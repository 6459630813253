
import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader' 
import {MeshSurfaceSampler} from 'three/examples/jsm/math/MeshSurfaceSampler'
import {EdgesGeometry} from 'three/src/geometries/EdgesGeometry'
import random from "random";
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js'

import vertex from "./shader/vertexShader"
import fragment from "./shader/fragmentShader"
import {gsap} from "gsap"
import * as dat from 'dat.gui';

class Model {
  constructor(obj) {

    this.name = obj.name
    this.file = obj.file
    this.scene = obj.scene
    this.particles = obj.particles
    this.placeOnLoad = obj.onLoad
    this.files = obj.files
    this.range = obj.range
    this.density = obj.density
    this.size = obj.size
    this.cubeSize = obj.cubeSize || 0.5
    this.direction = obj.direction 
    this.distribution = obj.distribution
    this.hasHole = obj.hasHole
    

    this.isActive = false
    this.loader = new GLTFLoader()
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath('./draco/')
    this.loader.setDRACOLoader(this.dracoLoader)

    this.init()
  }

  init() {


    this.files.forEach( (file)=> {

      this.group = new THREE.Group()
      this.geometry = ''

      this.loader.load(file, (res)=> {

        /*------------------------------
        Original Meshes
        ------------------------------*/
        let mesh = res.scene.children[0]
       
        //this.group.add(mesh)

        /*------------------------------
        Material Mesh
        ------------------------------*/
        let material = new THREE.MeshBasicMaterial({
          color: 'red',
          wireframe: true
        })

        

        /*------------------------------
        Geometries
        ------------------------------*/
        let sphere = new THREE.SphereGeometry( 1, 40, 20 )
        let geometry = mesh.geometry

       // console.log(sphere)

        /**
         Planes
         */

        //var planeGeometry = new THREE.PlaneGeometry( 0.1, 0.1, 1.6, 1.6 );
        let planeGeometry = new THREE.BoxGeometry(.04, .04, .04);
        var planeMaterial = new THREE.MeshBasicMaterial( { color:0xffffff } );
        

        /**
         Planes loop
         */

        //  var total = sphere.attributes.position.count
        //  var lookDirection = new THREE.Vector3();
        //  var target = new THREE.Vector3();
         

        //  for ( var i = 0; i < total * 3;  i += 3 ) {
       
        
        //      var planeMesh = new THREE.Mesh( planeGeometry, planeMaterial );

        //       let  x = sphere.attributes.position.array[i]
        //       let  y = sphere.attributes.position.array[i + 1]
        //       let  z = sphere.attributes.position.array[i + 2]

        //       const newPosition = new THREE.Vector3()

        //       newPosition.x = x
        //       newPosition.y = y
        //       newPosition.z = z

              
        //       planeMesh.position.copy( newPosition ); 
        //       lookDirection.subVectors( planeMesh.position, mesh.position ).normalize();
        //       target.copy( planeMesh.position ).add( lookDirection );
        //       planeMesh.lookAt( target );
             
        //     this.group.add( planeMesh );
           
        //  }



        /*-------
        Basic mesh
        ---------*/
        
        let basicMesh = new THREE.Mesh(sphere, material)

        //this.group.add(basicMesh)


        /*------------------------------
        Particles Material
        ------------------------------*/
        
        this.particlesMaterial = new THREE.ShaderMaterial({
          vertexShader: vertex,
          fragmentShader: fragment,
          transparent: false,
          side: THREE.DoubleSide,
          
          // depthWrite: false,
          // blending: THREE.AdditiveBlending,
          uniforms: {
            uTime: { value : 0},
            uScale: { value: 0}
          }
        })


         /**
         Particles Geometry Simple
         */

         let numParticles = this.particles
         let meshParticles = parseInt(numParticles / this.files.length)
         var total = geometry.attributes.position.count
         var lookDirection = new THREE.Vector3();
         var target = new THREE.Vector3();
         const particles = new Float32Array(meshParticles * 4)
         
        
         console.log(total)
  
       
         for ( var i = 0; i < meshParticles;  i += 3 ) {
       
        
            var planeMesh = new THREE.Mesh( planeGeometry, planeMaterial );

            let  x = geometry.attributes.position.array[i]
            let  y = geometry.attributes.position.array[i + 1]
            let  z = geometry.attributes.position.array[i + 2]

            const newPosition = new THREE.Vector3()
            const coin = Math.random();

            newPosition.x = x
            newPosition.y = y
            newPosition.z = z



            if(this.hasHole) {

           


            if (this.direction == 'x') {
              if (
                newPosition.y < this.range[0] ||
                newPosition.y > this.range[1]
              ) {
                particles.set(
                  [newPosition.x, newPosition.y, newPosition.z, this.cubeSize],
                  i * 4,
                )
              } else {
                if (coin > this.density) {
                  particles.set(
                    [
                      newPosition.x * 1,
                      newPosition.y * 1,
                      newPosition.z * 1,
                      random.float(this.size[0], this.size[1]),
                    ],
                    i * 4,
                  )
                }
              }
            } else {
              if (
                newPosition.x < this.range[0] ||
                newPosition.x > this.range[1]
              ) {
                particles.set(
                  [newPosition.x, newPosition.y, newPosition.z, this.cubeSize],
                  i * 4,
                )
              } else {
                if (coin > this.density) {
                  particles.set(
                    [
                      newPosition.x * 1,
                      newPosition.y * 1,
                      newPosition.z * 1,
                      random.float(this.size[0], this.size[1]),
                    ],
                    i * 4,
                  )
                }
              }
            }

          } else {

            particles.set([
              newPosition.x * 1,
              newPosition.y * 1,
              newPosition.z * 1,
              this.cubeSize

            ], i * 4)
          }


          
         }


        /*------------------------------
        Particles Geometry
        ------------------------------*/

        let sampler = new MeshSurfaceSampler(mesh)
        .setWeightAttribute( 'color' )
        .build()
        //let numParticles = this.particles
        //let meshParticles = parseInt(numParticles / this.files.length)
        
        const particlesCenter = new Float32Array(meshParticles * 4)
        const colors = new Float32Array(meshParticles * 3)
        const size = new Float32Array(meshParticles * 3)
        const angles = new Float32Array(meshParticles * 3);
       
        for (let i = 0; i < meshParticles; i++) {
          const newPosition = new THREE.Vector3()
          const coin = Math.random();
          
        
          sampler.sample(newPosition)


          if(this.hasHole) {

           
    
           if( this.direction == 'x' ) {

            
            if(newPosition.y < this.range[0] || newPosition.y > this.range[1] ) {
              particlesCenter.set([
                newPosition.x,
                newPosition.y,
                newPosition.z,
                this.cubeSize
              ], i * 4)
            } else {
              if (coin > this.density) {
                particlesCenter.set([
                  newPosition.x * 1,
                  newPosition.y * 1,
                  newPosition.z * 1,
                 random.float(this.size[0], this.size[1])
                ], i * 4)
              }
            }
          } else {
            if(newPosition.x < this.range[0] || newPosition.x > this.range[1] ) {
              particlesCenter.set([
                newPosition.x,
                newPosition.y,
                newPosition.z,
                this.cubeSize
              ], i * 4)
            } else {
              if (coin > this.density) {
                particlesCenter.set([
                  newPosition.x * 1,
                  newPosition.y * 1,
                  newPosition.z * 1,
                 random.float(this.size[0], this.size[1])
                ], i * 4)
              }
            }
          }

        } else {

          particlesCenter.set([
            newPosition.x  ,
            newPosition.y,
            newPosition.z ,
            this.cubeSize
          ], i * 4)
        }









          angles[i] = Math.random() * Math.PI;
          
          // Pick a color
          if (coin > .8) {
            colors[i] = Math.random();
          }
          size[i] = random.float(0.1, 1)         
        }
        
        const baseGeometry = new THREE.BoxGeometry(0.12, 0.12, 0.12);
        const instancedGeometry = new THREE.InstancedBufferGeometry().copy(baseGeometry);      
        instancedGeometry.instanceCount = meshParticles;   
        
        
        const distribution = this.distribution == 'vertices' ? particles : particlesCenter

       
       
        instancedGeometry.setAttribute('aCenter', new THREE.InstancedBufferAttribute(distribution, 4));
        instancedGeometry.setAttribute('aColor', new THREE.InstancedBufferAttribute(colors, 3))
        instancedGeometry.setAttribute(
          'aSize',
          new THREE.InstancedBufferAttribute(size, 3),
        )

        instancedGeometry.setAttribute('aAngle', new THREE.InstancedBufferAttribute(angles, 1, false));

        /*------------------------------
        Particles
        ------------------------------*/
        let model  = new THREE.InstancedMesh(instancedGeometry, this.particlesMaterial, meshParticles);

        this.group.add(model)
        this.group.name = this.name





        /*------------------------------
        On Load
        ------------------------------*/

        if(this.placeOnLoad) {
          this.add()
        }
      
      })
    })

  }

  add() {

    this.isActive = true

    this.scene.add(this.group)

    this.group.children.forEach( (el)=> {
      
      if(!el.material.uniforms) return

      gsap.to(el.material.uniforms.uScale, {value: 1 })
    })

    gsap.from(this.group.rotation, {y: 0.2, x: -0.2 })

   
  }


  remove() {  
    
    this.isActive = false

    this.group.children.forEach( (el)=> {

      if(!el.material.uniforms) return

      gsap.to(el.material.uniforms.uScale, {value: 0, onComplete: ()=> {
       //this.scene.remove(this.group)
       
      } })
    })
  }

}

export default Model