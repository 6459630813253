import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import {gsap} from "gsap"
import Model from './model'
import * as dat from 'dat.gui';

/*------------------------------
Renderer
------------------------------*/
const renderer = new THREE.WebGLRenderer({
  antialias: true,
  alpha: true
});
renderer.setSize( window.innerWidth, window.innerHeight );
document.body.appendChild( renderer.domElement );


/*------------------------------
Scene & Camera
------------------------------*/
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 
  50, 
  window.innerWidth / window.innerHeight,
  0.1,
  100
);
camera.position.z = 5;
camera.position.y = 1;


/*------------------------------
Mesh
------------------------------*/
const geometry = new THREE.BoxGeometry(2, 2, 2);
const material = new THREE.MeshBasicMaterial( { 
  color: 0x00ff00,
} );
const cube = new THREE.Mesh( geometry, material );
//scene.add( cube );


/*------------------------------
Light
------------------------------*/


//const light = new THREE.AmbientLight( 0x404040 ); // soft white light
//scene.add( light );

//const light = new THREE.PointLight( 0x000000, 1, 100 );
//light.position.set( 50, 50, 50 );
//scene.add( light );


/*------------------------------
OrbitControls
------------------------------*/
const controls = new OrbitControls( camera, renderer.domElement );


/*------------------------------
Helpers
------------------------------*/
const gridHelper = new THREE.GridHelper( 10, 10 );
//scene.add( gridHelper );
const axesHelper = new THREE.AxesHelper( 5 );
//scene.add( axesHelper );


/*------------------------------
Models
------------------------------*/

// const world = new Model({
//   name: 'world',
//   files: ['/models/continents.glb'],
//   scene: scene,
//   particles: 5000,
//   range: [-0.2, 0.3],
//   density: 0.4,
//   size: [0.3, 0.8],
// })

const settings  = {
  models: 'cloudwatch',
  particles: 5000,
  cubeSize: 0.5,
  density: 0.4,
  distribution: 'surface',
  rangeStart: -0.6,
  rangeEnd : 0.6,
  minSize: 0.1,
  maxSize: 1,
  hasHole: false,
  direction: 'x'
}

const gui = new dat.GUI()

const world = new Model({
  name: 'world',
  files: ['/models/continents.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})


const cloud = new Model({
  name: 'cloudwatch',
  files: ['/models/cloud.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
  onLoad: true,
  
})


const bug = new Model({
  name: 'threatwatch',
  files: ['/models/bug.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})

const lock = new Model({
  name: 'cybersecurity',
  files: ['/models/lock.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})

const ball = new Model({
  name: 'basketball',
  files: ['/models/ball.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})

const key = new Model({
  name: 'partner',
  files: [
    '/models/keys.glb',
  ],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})

const badge = new Model({
  name: 'company',
  files: ['/models/badge.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})

const bubble = new Model({
  name: 'contact',
  files: ['/models/bubble.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})

const wine = new Model({
  name: 'wine',
  files: ['/models/wine.glb'],
  scene: scene,
  particles: 5000,
  range: [settings.rangeStart, settings.rangeEnd],
  density: settings.density,
  size: [settings.minSize, settings.maxSize],
  cubeSize: settings.cubeSize,
  distribution: settings.distribution,
  direction: settings.direction,
  hasHole: settings.hasHole,
})



const models = [cloud, world, bug, bubble, wine, lock, ball, key, badge ]

gui.add(settings, 'models', [ 'cloudwatch', 'world', 'threatwatch', 'cybersecurity', 'contact', 'wine', 'basketball', 'company', 'partner' ] ).onChange( (value)=> {

  models.forEach( (model)=> {
    model.onLoad = false
    model.remove()
    if(value == model.name) {
      model.onLoad = true
      model.particles = settings.particles
      model.cubeSize = settings.cubeSize
      model.add()
    }
  })

})

gui.add(settings, 'distribution', [ 'vertices', 'surface' ] ).onChange( (value)=> {

  models.forEach( (model)=> {

    if(model.name == settings.models ) {
     
      scene.children.forEach( (child)=> {
        if(child.name == settings.models) scene.remove(child)
       })
      
      model.distribution = value
      model.placeOnLoad = true
      model.init()
    }
   })

})

gui.add(settings, 'particles', 1000, 100000).onChange( (value)=> {
  updateModel()
})

gui.add(settings, 'cubeSize', 0.1, 1).onChange( (value)=> {
  updateModel()
})

const f1 = gui.addFolder('Hole')

f1.add(settings, 'hasHole').onChange(function (value) {
  updateModel()
}); 

f1.add(settings, 'direction', [ 'x', 'y' ] ).onChange(function (value) {
  updateModel()
})

f1.add(settings, 'rangeStart', -1, 1).onChange( (value)=> {
  updateModel()
})

f1.add(settings, 'rangeEnd', -1, 1).onChange( (value)=> {
  updateModel()
})

f1.add(settings, 'density', 0, 1).onChange( (value)=> {
  updateModel()
})

f1.add(settings, 'minSize', 0.1, 1).onChange( (value)=> {
  updateModel()
})

f1.add(settings, 'maxSize', 0.1, 1).onChange( (value)=> {
  updateModel()
})


const updateModel = (val)=> {

  models.forEach( (model)=> {

    if(model.name == settings.models ) {
     
      scene.children.forEach( (child)=> {
        if(child.name == settings.models) scene.remove(child)
       })
      

      model.size = [settings.minSize, settings.maxSize]
      model.range = [settings.rangeStart, settings.rangeEnd]
      model.density = settings.density
      model.particles = settings.particles
      model.cubeSize = settings.cubeSize
      model.hasHole = settings.hasHole
      model.direction = settings.direction
      model.placeOnLoad = true
      model.init()
    }
   })
}





/*------------------------------
Controllers
------------------------------*/



/*------------------------------
clock
------------------------------*/

const clock = new THREE.Clock()

/*------------------------------
Loop
------------------------------*/
const animate = function () {
  requestAnimationFrame( animate );
  renderer.render( scene, camera );

};
animate();


/*------------------------------
Resize
------------------------------*/
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}
window.addEventListener( 'resize', onWindowResize, false );

/*------------------------------
MouseMove
------------------------------*/

function onMousemove(e) {
  const x = e.clientX
  const y = e.clientY

  gsap.to(scene.rotation, {
    y: gsap.utils.mapRange(0, window.innerWidth, 0.2, -0.2, x ),
    x: gsap.utils.mapRange(0, window.innerHeight, 0.2, -0.2, y ),
  })
}

window.addEventListener('mousemove', onMousemove)